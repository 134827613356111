import React from 'react';
import { niceScrollTo } from '../../utilities';
import './back-to-top.scss';

const scrollTop = function scrollBackToTop() {
  niceScrollTo({
    x: 0,
    y: 0,
    speed: 1,
  });
};

const BackToTop = () => (
  <button onClick={scrollTop} type="button" className="backToTop">
    back to top
  </button>
);

export default BackToTop;
