import {
  Ease,
  TweenLite,
} from 'gsap';

const obj = {};

function onUpdate() {
  window.scrollTo(obj.x, obj.y);
}

export default function niceScrollTo(props = {}) {
  const {
    animate = true,
    callback = false,
    ease = Ease.easeInOut,
    speed = 1.4,
    x = 0,
    y = 0,
  } = props;

  if (typeof window !== 'undefined' && window) {
    obj.x = Math.max(document.body.scrollLeft, window.pageXOffset);
    obj.y = Math.max(document.body.scrollTop, window.pageYOffset);

    if (animate && typeof (TweenLite) !== 'undefined') {
      TweenLite.to(obj, speed, {
        ease,
        onUpdate,
        onComplete: callback,
        x,
        y,
      });
    } else {
      window.scrollTo(x, y);
      if (callback) callback();
    }
  }
}
