import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';

import BackToTop from '../back-to-top/back-to-top';
import Footer from '../footer/footer';
import Header from '../header/header';
import Newsletter from '../newsletter/newsletter';
import Transition from '../transition/transition';

import './reset.scss';
import './layout.scss';

import LayoutContext from './layout-context';

import {
  getMetadata,
  getSiblingMargin,
  isClient,
  offset,
} from '../../helpers';

import {
  HEADER_HEIGHT,
} from '../../config';

import {
  scrolling,
} from '../../utilities';

const forceScrollTimeouts = {
  first: null,
  second: null,
};

const forceScroll = (y = 0) => {
  clearTimeout(forceScrollTimeouts.first);
  clearTimeout(forceScrollTimeouts.second);

  window.scrollTo(0, y);

  forceScrollTimeouts.first = setTimeout(() => {
    window.scrollTo(0, y);
  }, 100);

  forceScrollTimeouts.second = setTimeout(() => {
    window.scrollTo(0, y);
  }, 200);
};

const scrollToHash = (hash = '') => {
  if (!hash) {
    return;
  }
  // Change in hash (link to a different section of the page)
  const target = document.querySelector(`[name="${hash.replace('#', '')}"]`);

  if (target) {
    const y = offset(target).top - HEADER_HEIGHT + getSiblingMargin(target) + 1;
    forceScroll(y);
  }
};

class Layout extends React.Component {
  constructor(props) {
    super(props);
    const { location: { pathname } } = props;

    this.getTitleFromConfig = this.getTitleFromConfig.bind(this);
    this.makeHeaderSolid = this.makeHeaderSolid.bind(this);
    this.makeHeaderTransparent = this.makeHeaderTransparent.bind(this);
    this.renderLayout = this.renderLayout.bind(this);
    this.toggleNav = this.toggleNav.bind(this);

    this.state = {
      headerTransparent: false,
      locationHistory: [pathname],
      navOpen: false,
      paths: ['/'],
      scrolls: [0],
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (!isClient()) {
      return state;
    }

    const { location } = props;
    const { hash, pathname } = window.location;
    const { locationHistory, paths, scrolls } = state;

    const updatedHistory = [...locationHistory];
    if (updatedHistory.length && updatedHistory[updatedHistory.length - 1] !== location.pathname) {
      updatedHistory.push(location.pathname);

      return {
        ...state,
        locationHistory: updatedHistory,
        paths: [
          ...paths,
          `${pathname}${hash}`,
        ],
        scrolls: [
          ...scrolls,
          scrolling.getScrollY(),
        ],
      };
    }

    return {
      ...state,
    };
  };

  componentDidMount() {
    const { hash } = window.location;

    scrolling.init();
    scrollToHash(hash);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { pathname } = location;
    const { hash } = window.location;
    const {
      paths, scrolls, navOpen, headerTransparent,
    } = this.state;

    if (pathname !== prevProps.location.pathname) {
      // Restore the scroll position
      if (paths.length > 2 && paths.slice(-3, -2).pop() === `${pathname}${hash}`) {
        forceScroll(scrolls.slice(-2, -1).pop());
      } else if (hash) {
        // Jumplink
        scrollToHash(hash);
      }
    }

    document.body.classList[navOpen ? 'add' : 'remove']('state--nav-open');
    document.body.classList[headerTransparent ? 'add' : 'remove']('state--header-transparent');

    scrolling.rebuildCache();
  }

  getTitleFromConfig(data) {
    const { path } = this.props;
    const { props } = this;

    const currentPath = path.split('/').map(chunk => props[chunk] || '').join('/') || '';
    const match = get(data, 'site.siteMetadata.staticPages', []).find(item => currentPath.indexOf(item.path) > -1);

    return match && match.title ? match.title : '';
  }

  toggleNav() {
    const { navOpen } = this.state;
    this.setState({
      navOpen: !navOpen,
    });
  }

  makeHeaderTransparent() {
    this.setState({
      headerTransparent: true,
    });
  }

  makeHeaderSolid() {
    this.setState({
      headerTransparent: false,
    });
  }

  closeNav() {
    this.setState({
      navOpen: false,
    });
  }

  renderLayout(data) {
    const {
      children,
      location,
      title,
      pageContext,
    } = this.props;

    const {
      locationHistory,
      navOpen,
    } = this.state;

    const defalutMeta = get(data, 'site.siteMetadata', '');
    const pageTitle = this.getTitleFromConfig(data) || get(pageContext, 'metaTitle', '') || get(pageContext, 'title', '') || title;

    return (
      <React.Fragment>
        <Helmet>
          <html lang="en" />
          <title>{get(defalutMeta, 'title', '')}</title>
          <link rel="canonical" href={get(defalutMeta, 'siteUrl', '')} />
          <meta name="description" content={get(defalutMeta, 'description', '')} />
          <meta property="og:description" content={get(defalutMeta, 'description', '')} />
          <meta property="og:title" content={get(defalutMeta, 'title', '')} />
          <meta property="og:image" content={`${get(defalutMeta, 'siteUrl', '')}${get(defalutMeta, 'ogImage', '')}`} />
        </Helmet>
        <Helmet
          title={pageTitle === title ? title : `${pageTitle} - ${title}`}
          meta={getMetadata(pageContext)}
        />
        <Transition location={location}>
          <React.Fragment>
            <Header
              location={location}
              navOpen={navOpen}
              siteTitle={(location.pathname === '/' && 'home') || pageTitle}
              toggleNav={this.toggleNav}
            >
              <Newsletter componentName="header" />
            </Header>
            <main className="main">
              <LayoutContext.Provider value={({
                location,
                locationHistory,
                makeHeaderSolid: this.makeHeaderSolid,
                makeHeaderTransparent: this.makeHeaderTransparent,
                navOpen,
              })}
              >
                {children}
              </LayoutContext.Provider>
              <BackToTop />
              <Footer>
                <Newsletter componentName="footer" />
              </Footer>
            </main>
          </React.Fragment>
        </Transition>
      </React.Fragment>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                description
                siteUrl
                ogImage
                staticPages {
                  path
                  title
                }
              }
            }
          }
        `}
        render={this.renderLayout}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    title: PropTypes.string,
  }),
  path: PropTypes.string,
  title: PropTypes.string,
};

Layout.defaultProps = {
  location: {
    pathname: '',
  },
  pageContext: { title: '' },
  path: '',
  title: 'Dzek',
};

export default Layout;
