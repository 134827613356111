import React from 'react';
import PropTypes from 'prop-types';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group';

import {
  scrolling,
} from '../../utilities';

import {
  isClient,
} from '../../helpers';

const timeoutEnter = 0;
const timeoutExit = 500;

if (isClient() && 'scrollRestoration' in window.history) {
  // Back off, browser, I got this...
  window.history.scrollRestoration = 'manual';
}

const getTransitionStyles = (status, scrollY = 0) => {
  const styles = {
    entering: {
      opacity: 0,
    },
    entered: {
      transition: `opacity ${timeoutExit}ms ease-in-out`,
      opacity: 1,
    },
    exiting: {
      left: 0,
      marginTop: `-${scrollY}px`,
      minHeight: '100%',
      opacity: 0,
      position: 'fixed',
      top: 0,
      transition: `opacity ${timeoutExit}ms ease-in-out`,
      width: '100%',
    },
  };

  return styles[status];
};

const Transition = (props) => {
  const {
    children,
    location,
  } = props;

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeoutEnter,
          exit: timeoutExit,
        }}
      >
        {status => (
          <div
            className={`transition--${status} ${['exiting', 'exited'].indexOf(status) < 0 ? 'js-scrollingEnabled' : ''}`}
            style={{
              ...getTransitionStyles(status, isClient() ? scrolling.getScrollY() : 0),
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Transition;
