import React from 'react';

const LayoutContext = React.createContext({
  headerTransparent: false,
  location: {},
  locationHistory: [],
  makeHeaderSolid: () => {},
  makeHeaderTransparent: () => {},
  navOpen: false,
});

export default LayoutContext;
