// depends on existence of `window` do not use server side

const UNIQUE_VISITED_PATHS_KEY = 'uniqueVisitedPaths';

const get = function getDecodedValue(key) {
  return JSON.parse(window.sessionStorage.getItem(key) || '[]');
};

const has = function testKeyForValue(key, value) {
  return get(key).indexOf(value) !== -1;
};

const push = function pushToSession(key, value) {
  const values = get(key);
  values.push(value);
  window.sessionStorage.setItem(key, JSON.stringify(values));
};

const wasPathVisited = function isCurrentPathInUniqueVisitedPaths() {
  const path = window.location.pathname;
  if (has(UNIQUE_VISITED_PATHS_KEY, path)) {
    return true;
  }
  push(UNIQUE_VISITED_PATHS_KEY, path);
  return false;
};

export default {
  get,
  has,
  push,
  wasPathVisited,
};
