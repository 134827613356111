export default function compareDate(condition, d) {
  const now = new Date();
  const dates = (d.constructor === Array ? d : [d]).map(date => new Date(date));

  if (condition === '<>' && dates.length > 1) {
    return dates[0] < now && now < dates[1];
  }
  if (condition === '<') {
    return dates[0] < now;
  }
  if (condition === '>') {
    return dates[0] > now;
  }

  return -1;
}
