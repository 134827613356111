// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-case-study-js": () => import("./../../../src/containers/case-study.js" /* webpackChunkName: "component---src-containers-case-study-js" */),
  "component---src-containers-exhibition-js": () => import("./../../../src/containers/exhibition.js" /* webpackChunkName: "component---src-containers-exhibition-js" */),
  "component---src-containers-news-js": () => import("./../../../src/containers/news.js" /* webpackChunkName: "component---src-containers-news-js" */),
  "component---src-containers-page-js": () => import("./../../../src/containers/page.js" /* webpackChunkName: "component---src-containers-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

