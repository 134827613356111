import isClient from './is-client';

export default function isTouch() {
  if (!isClient()) {
    return false;
  }
  return (
    ('ontouchstart' in window)
    || (window.DocumentTouch && document instanceof window.DocumentTouch)
  );
}
