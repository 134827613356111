/*
 * Controls the on-scroll animations
 * Allows other modules to add callbacks via public methods
 */
import { camelToDash } from '../helpers';

const scrolling = (() => {
  if (!(typeof window !== 'undefined' && window)) {
    return {
      init: () => {},
      scrollTo: () => {},
    };
  }

  window.requestAnimFrame = (() => window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || false
  )();

  // selectors
  const SELECTOR_ELEMENTS = '.js-scrollingEnabled .js-scroll';

  const state = {
    disabled: false,
    resizeTimeout: null,
  };

  // cache
  let cache = [];
  let scrollTop;
  let windowHeight;
  let windowWidth; // eslint-disable-line no-unused-vars
  let elements;

  // states
  let isActive = false;
  let scrollingUp = false;
  let previousScrollTop = 0;

  // callbacks triggered only when element is in view
  const callbacks = {};

  // callbacks triggered always when the element is on the page
  const alwaysCallbacks = {};

  function indexElements() {
    elements = document.querySelectorAll(SELECTOR_ELEMENTS);
  }

  function addCallback(callbackName, callbackFunction, always = false) {
    if (always) {
      alwaysCallbacks[callbackName] = callbackFunction;
    } else {
      callbacks[callbackName] = callbackFunction;
    }
  }

  function saveElementCache(el) {
    const boundingRect = el.getBoundingClientRect();
    const currentScrollTop = window.scrollY || window.pageYOffset;
    const callback = el.getAttribute('data-callback') || false;

    return {
      el,
      callback,
      top: boundingRect.top + currentScrollTop,
      left: boundingRect.left,
      height: el.offsetHeight,
    };
  }

  function buildCache() {
    cache = Array.prototype.map.call(elements, saveElementCache) || [];
  }

  function rebuildCache() {
    indexElements();
    buildCache();
  }

  function triggerElementCallback(elCache, always = false) {
    let elCacheOutput = elCache;
    if (elCache.callback) {
      if (always && elCache.callback in alwaysCallbacks) {
        // assuming the callback is always a function, not testing --> want it to happen FAST
        elCacheOutput = alwaysCallbacks[elCache.callback](elCache, scrollTop) || elCacheOutput;
      } else if (elCache.callback in callbacks) {
        // assuming the callback is always a function, not testing --> want it to happen FAST
        elCacheOutput = callbacks[elCache.callback](elCache, scrollTop) || elCacheOutput;
      }
    }

    return elCacheOutput;
  }

  function diffRenderStates(elCache, states, oldStates) {
    /*
    checks the element's calculated state against its current state
    and applies relevant classes if the state changes
    */
    Object.keys(states).forEach((key) => {
      const stateProp = states[key];
      const stateDash = camelToDash(key);
      if (!oldStates) {
        if (stateProp) {
          elCache.el.classList.add(stateDash);
        } else {
          elCache.el.classList.remove(stateDash);
        }
      } else if (stateProp !== elCache.states[key]) {
        if (stateProp) {
          elCache.el.classList.add(stateDash);
        } else {
          elCache.el.classList.remove(stateDash);
        }
      }
    });
  }

  const scrollConditions = [
    {
      name: 'isInView',
      test: props => (props.scrollTop + props.windowHeight > props.top)
        && (props.scrollTop <= props.top + props.height),
    },
    {
      name: 'isPastHalf',
      test: props => props.scrollTop + (props.windowHeight / 2) >= props.top,
    },
    {
      name: 'isPastQuarter',
      test: props => props.scrollTop + (props.windowHeight * 0.75) >= props.top,
    },
    {
      name: 'isPastTop',
      test: props => props.scrollTop >= props.top,
    },
    {
      name: 'isPastBottom',
      test: props => props.scrollTop + props.windowHeight > props.top + props.height,
    },
    {
      name: 'isPastMiddle',
      test: props => props.scrollTop + (props.windowHeight / 2) > props.top + (props.height * 0.5),
    },
    {
      name: 'isHalfPastBottom',
      test: props => props.scrollTop + props.windowHeight > props.top + (props.height * 1.5),
    },
    {
      name: 'isQuarterPastBottom',
      test: props => props.scrollTop + props.windowHeight > props.top + (props.height * 1.25),
    },
    {
      name: 'isAtBottom',
      test: props => props.scrollTop + props.windowHeight + 1 >= props.top + props.height,
    },
  ];

  function renderElement(previousCache) {
    let elCache = previousCache;
    const states = {};

    // IDEA: transform states into an array & use map
    scrollConditions.forEach((cond) => {
      states[cond.name] = cond.test({
        scrollTop,
        windowHeight,
        top: elCache.top,
        height: elCache.height,
      });
    });

    if (states.isInView) {
      elCache = triggerElementCallback(elCache) || elCache;
    }

    if (elCache.states) {
      diffRenderStates(elCache, states, elCache.states);
    } else {
      diffRenderStates(elCache, states);
    }

    elCache = triggerElementCallback(elCache, true) || elCache;

    elCache.states = states;

    return elCache;
  }

  function onScrollHooks() {
    if (previousScrollTop > scrollTop && !scrollingUp && scrollTop > 0) {
      document.body.classList.add('scrolling-up');
      scrollingUp = true;
    } else if ((previousScrollTop < scrollTop) && scrollingUp) {
      document.body.classList.remove('scrolling-up');
      scrollingUp = false;
    }
    previousScrollTop = scrollTop;
  }

  function render() {
    if (state.disabled) {
      return false;
    }
    scrollTop = window.scrollY || window.pageYOffset;
    cache = cache.map(renderElement);

    onScrollHooks();

    return true;
  }

  function onFrame() {
    if (!isActive) {
      return false;
    }
    render();
    window.requestAnimFrame(onFrame);
    return true;
  }

  function cacheWindowSize() {
    windowHeight = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;
    windowWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
  }

  function cacheOnResize() {
    cacheWindowSize();
    buildCache();
  }

  function onResize() {
    clearTimeout(state.resizeTimeout);
    state.resizeTimeout = setTimeout(() => {
      window.requestAnimFrame(cacheOnResize);
    }, 100);
  }

  function init() {
    isActive = true;
    indexElements();
    cacheWindowSize();
    buildCache();
    onFrame();
    window.addEventListener('resize', onResize);
  }

  function destroy() {
    isActive = false;
    window.removeEventListener('resize', onResize);
    elements = null;
  }

  function getWindowHeight() {
    return windowHeight;
  }

  function getWindowWidth() {
    return windowWidth;
  }

  function getScrollY() {
    return scrollTop;
  }

  function disable() {
    state.disabled = true;
  }

  function enable() {
    state.disabled = false;
  }

  return {
    addCallback,
    buildCache,
    destroy,
    disable,
    enable,
    indexElements,
    init,
    getCache: () => cache,
    getScrollY,
    getWindowHeight,
    getWindowWidth,
    rebuildCache,
  };
})();

export default scrolling;
