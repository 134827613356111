import isClient from './is-client';

export default function offset(el) {
  if (!isClient()) {
    return {
      left: 0,
      top: 0,
    };
  }

  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    left: rect.left + scrollLeft,
    top: rect.top + scrollTop,
  };
}
