import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { subscribe, validateEmail } from '../../utilities/subscribe';

import './newsletter.scss';

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.clearMessage = this.clearMessage.bind(this);
    this.getErrorClass = this.getErrorClass.bind(this);
    this.handleSubscribeResponse = this.handleSubscribeResponse.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.onSubscribeSubmit = this.onSubscribeSubmit.bind(this);

    this.refForm = React.createRef();
    this.msgTimeout = null;
    this.validationActive = false;

    this.state = {
      canSubmit: true,
      errors: [],
      msg: '',
    };
  }

  onSubscribeSubmit(ev) {
    ev.preventDefault();
    const form = this.refForm.current;

    this.validationActive = true;
    this.setState({
      canSubmit: false,
    });

    if (!this.isFormValid()) return false;

    subscribe({ email: form.elements.email.value })
      .then(this.handleSubscribeResponse)
      .catch(err => this.setState({
        errors: [{
          name: 'email',
          msg: err,
        }],
        canSubmit: false,
      }));
    return true;
  }

  getErrorClass(name) {
    const { errors } = this.state;
    const hasError = errors.some(error => error.name === name);
    return hasError ? 'has-error' : '';
  }

  clearMessage() {
    this.setState({
      msg: '',
      canSubmit: true,
    });
  }

  handleSubscribeResponse(result) {
    this.setState({
      msg: result,
    });
    clearTimeout(this.msgTimeout);
    this.msgTimeout = setTimeout(this.clearMessage, 8000);
  }

  isFormValid() {
    const form = this.refForm.current;
    const errors = [];

    if (!this.validationActive) return true;

    [].forEach.call(form.elements, (el) => {
      if (
        (el.required && !el.value)
        || (el.type === 'checkbox' && !el.checked)
      ) {
        errors.push({
          name: el.name,
          msg: 'Some required values are missing.',
        });
      } else if (el.type === 'email' && !validateEmail(el.value)) {
        errors.push({
          name: el.name,
          msg: 'Provide valid e-mail address.',
        });
      }
    });
    this.setState({
      errors,
      canSubmit: !errors.length,
    });
    return !errors.length;
  }

  render() {
    const {
      componentName,
      policyLink,
    } = this.props;
    const {
      errors,
      canSubmit,
      msg,
    } = this.state;

    // NOTE: need to be able to render HTML to get the links in MailChimp responses to work
    /* eslint-disable react/no-danger */
    return (
      <form
        className={`newsletter newsletter--${componentName}`}
        noValidate
        onSubmit={this.onSubscribeSubmit}
        ref={this.refForm}
      >
        <div className="newsletter__input newsletter__wrap">
          <input
            id={`${componentName}-email`}
            name="email"
            noValidate
            onChange={this.isFormValid}
            placeholder="Enter your email to subscribe to our newsletter"
            required
            type="email"
          />
        </div>
        <div className="newsletter__wrap">
          <div className="checkbox__round">
            <input
              id={`${componentName}-newsletterCheckbox`}
              name="consent"
              noValidate
              onChange={this.isFormValid}
              required
              type="checkbox"
            />
            <label htmlFor={`${componentName}-newsletterCheckbox`}>
              <span className="checkbox__label">
                I accept the&nbsp;
                <Link to={policyLink}>Privacy & Data Policy</Link>
              </span>
            </label>
          </div>
        </div>

        <button
          className="btn__submit newsletter__wrap"
          disabled={!canSubmit}
          type="submit"
        >
          <span>Submit</span>
        </button>

        {!errors.length && (
          <div className={`newsletter__success ${msg ? 'is-visible' : ''}`}>
            <span>{msg}</span>
          </div>
        )}

        {!!errors.length && (
          <div className="newsletter__errors">
            {errors.map(error => (
              <p
                className="newsletter__errors__line"
                dangerouslySetInnerHTML={{ __html: error.msg }}
                key={[error.name, error.msg].join('')}
              />
            ))}
          </div>
        )}
      </form>
    );
    /* eslint-enable react/no-danger */
  }
}

Newsletter.propTypes = {
  componentName: PropTypes.string,
  policyLink: PropTypes.string,
};

Newsletter.defaultProps = {
  componentName: 'subscribe',
  policyLink: '/legal',
};

export default Newsletter;
