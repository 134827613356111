import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
  Link,
  navigate,
  StaticQuery,
} from 'gatsby';
import get from 'lodash.get';
import url from 'url';

import { HEADER_HEIGHT } from '../../config';
import {
  getPhoneNumber,
  getSiblingMargin,
  offset,
} from '../../helpers';
import { niceScrollTo } from '../../utilities';

import './header.scss';
import Logo from './logo.svg';

const HEADER_QUERY = graphql`
  {
    contentfulMenu(slug: {eq: "main-menu"}) {
      menu: submenu {
        ...menuFields
        submenu {
          ...menuFields
          submenu {
            ...menuFields
            submenu {
              ...menuFields
            }
          }
        }
      }
    }
  }

  fragment menuFields on ContentfulMenu {
    title
    slug
    link
  }
`;

const year = (new Date()).getFullYear();

const getTree = function getMenuTree(items = []) {
  return (items || []).map(item => ({
    url: item.link,
    label: item.title,
    links: getTree(item.submenu),
  }));
};

const getRoutes = function getMainMenuRoutes(data) {
  const menu = get(data, 'contentfulMenu.menu', []);
  return getTree(menu);
};

const Header = ({
  address,
  children,
  email,
  instagram,
  location,
  navOpen,
  phone,
  siteTitle,
  toggleNav,
}) => {
  const onNavLinkClick = function onNavLinkClickAction(ev) {
    ev.preventDefault();
    const el = ev.currentTarget;
    const linkUrl = url.parse(el.getAttribute('href'));

    toggleNav(ev);

    // different path, navigate
    if (location.pathname !== linkUrl.pathname) {
      navigate(linkUrl.href);
    }

    // same path no hash, scroll to top
    if (!linkUrl.hash) {
      window.history.replaceState(null, '', linkUrl.pathname);
      niceScrollTo({
        speed: 1,
        y: 0,
      });
      return;
    }

    // same path with hash, scroll to hash
    const name = linkUrl.hash.replace('#', '');
    const target = document.querySelector(`[name="${name}"]`);
    const state = `${linkUrl.pathname}#${name}`;
    if (target) {
      window.history.replaceState(null, '', state);
      niceScrollTo({
        speed: 1,
        y: offset(target).top - HEADER_HEIGHT + getSiblingMargin(target) + 1,
      });
    }
  };

  return (
    <StaticQuery
      query={HEADER_QUERY}
      render={(data) => {
        const routes = getRoutes(data);
        return (
          <Fragment>
            <div className="header-overlay" onClick={toggleNav} aria-hidden="true" />
            <div className="header">
              <Link to="/" className="header__logo">
                <Logo />
              </Link>
              <button onClick={toggleNav} type="button" className="header__toggle">
                menu
              </button>
              <h1 className="header__title">
                {siteTitle}
              </h1>

              <nav
                className={[
                  'header__nav',
                  navOpen ? 'header__nav--open' : '',
                ].join(' ').trim()}
              >
                <div className="header__nav__content">
                  <ul className={`header__nav__items header__nav__items--${routes.length}`}>
                    {routes.map(item => (
                      <li key={item.url + item.label} className="header__nav__item">
                        <a href={item.url || '#'} onClick={onNavLinkClick}>{item.label}</a>
                        {item.links && !!item.links.length && (
                          <ul>
                            {item.links.map(subItem => (
                              <li key={subItem.url + subItem.label}>
                                <a href={subItem.url || '#'} onClick={onNavLinkClick}>
                                  {subItem.label}
                                </a>
                                {subItem.links && !!subItem.links.length && (
                                  <ul>
                                    {subItem.links.map(subSubItem => (
                                      <li key={subSubItem.url + subSubItem.label}>
                                        <a href={subSubItem.url || '#'} onClick={onNavLinkClick}>
                                          <span>{subSubItem.label}</span>
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul className="header__nav__info">
                    <li>
                      <a href={`mailto:${email}`}>{email}</a>
                      <br />
                      <a href={`tel:${getPhoneNumber(phone)}`}>{phone}</a>
                      <br />
                      <a href={`https://maps.google.com?q=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
                        <u>{address}</u>
                      </a>
                    </li>
                    {React.Children.map(children, child => (
                      <li>
                        {child}
                      </li>
                    ))}
                    <li>
                      <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
                        Instagram: @
                        {instagram}
                      </a>
                    </li>
                    <li>
                      <small>
                        &copy;
                        {year}
                        &nbsp;dzek ltd
                      </small>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

Header.defaultProps = {
  address: '31 Oval Road, London, NW1 7EA',
  email: 'studio@dzekdzekdzek.com',
  instagram: 'dzekdzekdzek',
  navOpen: false,
  phone: '+44 (0)207 419 1937',
  siteTitle: '',
};

Header.propTypes = {
  address: PropTypes.string,
  children: PropTypes.node.isRequired,
  email: PropTypes.string,
  instagram: PropTypes.string,
  location: PropTypes.string.isRequired,
  navOpen: PropTypes.bool,
  phone: PropTypes.string,
  siteTitle: PropTypes.string,
  toggleNav: PropTypes.func.isRequired,
};

export default Header;
