import isClient from './is-client';

export default function getSiblingMargin(target) {
  if (!isClient()) {
    return 0;
  }
  const sibling = target.nextElementSibling;
  if (!sibling) {
    return 0;
  }
  const marginTop = window.getComputedStyle(sibling).marginTop || '0';
  return parseInt(marginTop, 10);
}
