import PropTypes from 'prop-types';
import React from 'react';

import {
  getPhoneNumber,
} from '../../helpers';

import './footer.scss';

const year = (new Date()).getFullYear();

const Footer = ({
  address,
  children,
  email,
  instagram,
  phone,
}) => (
  <div className="footer">
    <div className="footer__contact">
      <a href={`mailto:${email}`}>{email}</a>
      <a href={`tel:${getPhoneNumber(phone)}`}>{phone}</a>
      <a href={`https://maps.google.com?q=${encodeURIComponent(address)}`} target="_blank" rel="noopener noreferrer">
        {address}
      </a>
    </div>
    <div className="footer__social">
      Instagram:&nbsp;
      <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
        @
        {instagram}
      </a>
    </div>

    {children}

    <div className="footer__legal">
      &copy;
      {year}
      &nbsp;dzek ltd.
    </div>
  </div>
);

Footer.defaultProps = {
  address: '31 Oval Road, London, NW1 7EA',
  email: 'studio@dzekdzekdzek.com',
  instagram: 'dzekdzekdzek',
  phone: '+44 (0)207 419 1937',
};

Footer.propTypes = {
  address: PropTypes.string,
  children: PropTypes.node.isRequired,
  email: PropTypes.string,
  instagram: PropTypes.string,
  phone: PropTypes.string,
};

export default Footer;
