export default function getExhibitionSideNavLinks(currentItems, pastItems, upcomingItems) {
  const links = [];
  if (currentItems.length) {
    links.push({
      id: 'nav-current',
      parent: '',
      slug: 'current',
      title: 'Current',
    });
  }
  if (upcomingItems.length) {
    links.push({
      id: 'nav-upcoming',
      parent: '',
      slug: 'upcoming',
      title: 'Upcoming',
    });
  }
  if (pastItems.length) {
    links.push({
      id: 'nav-past',
      parent: '',
      slug: 'past',
      title: 'Past',
    });
  }
  return links;
}
