/* eslint-disable no-useless-escape, no-control-regex */
export const validateEmail = (email = '') => /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email); // eslint-disable-line max-len
/* eslint-enable no-useless-escape, no-control-regex */

export const subscribe = (props = {}) => new Promise((resolve, reject) => {
  const { email } = props;

  const callbackName = 'callback';
  const timeout = 4; // sec
  let script;

  if (!email || !validateEmail(email)) {
    reject(new Error('Incorrect Email format'));
    return;
  }

  const timeoutTrigger = window.setTimeout(() => {
    window[callbackName] = () => {};
    reject(new Error('Request timed out'));
  }, timeout * 1000);

  window[callbackName] = (data) => {
    window.clearTimeout(timeoutTrigger);

    if (data.result === 'success') {
      resolve(data.msg);
    } else {
      reject(data.msg);
    }

    script.parentNode.removeChild(script);
  };

  script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `${process.env.GATSBY_MAILCHIMP_URL}&EMAIL=${email}&SOURCE=website&c=callback`;

  document.body.appendChild(script);
});

export default subscribe;
