import get from 'lodash.get';

const getMetadata = function getMetadataArray(pageData) {
  let imageUrl = get(pageData, 'ogImage.file.url', '');
  if (imageUrl) {
    imageUrl = `${imageUrl}?fit=fill&w=1200&h=630`;
  }
  const meta = [
    { name: 'description', content: get(pageData, 'metaDescription.metaDescription', '') },
    { property: 'og:site_name', content: 'DZEK Ltd.' },
    { property: 'og:url', content: process.env.GATSBY_BASE_URL },
    { property: 'og:title', content: get(pageData, 'metaTitle', '') },
    { property: 'og:type', content: get(pageData, 'metaType', '') },
    { property: 'og:description', content: get(pageData, 'metaDescription.metaDescription', '') },
    { property: 'og:image', content: imageUrl },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:title', content: get(pageData, 'metaTitle', '') },
    { name: 'twitter:description', content: get(pageData, 'metaDescription.metaDescription', '') },
  ];

  return meta.reduce((output, value) => {
    if (value.content) {
      output.push(value);
    }
    return output;
  }, []);
};

export default getMetadata;
