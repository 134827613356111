/* eslint-disable no-console */
const contentful = require('contentful');

const client = contentful.createClient({
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
});

const loadAwards = function loadAwardsItems(skip = 0, limit = 10) {
  return client.getEntries({
    content_type: 'award',
    limit,
    order: '-fields.date',
    skip,
  })
    .then(response => response.items)
    .catch(console.error);
};

const loadNews = function loadNewsItems(skip = 0, limit = 10) {
  return client.getEntries({
    content_type: 'news',
    include: 2,
    limit,
    order: '-fields.date',
    skip,
  })
    .then(response => response.items)
    .catch(console.error);
};

const loadPress = function loadPressItems(skip = 0, limit = 10) {
  return client.getEntries({
    content_type: 'press',
    limit,
    order: '-fields.date',
    skip,
  })
    .then(response => response.items)
    .catch(console.error);
};

export default {
  loadAwards,
  loadNews,
  loadPress,
};
